@import url(~normalize.css);
@import url(~@blueprintjs/icons/lib/css/blueprint-icons.css);
@import url(~@blueprintjs/core/lib/css/blueprint.css);
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.auto-margin {
  margin: auto; }

.auto-margin-x {
  margin: 0 auto; }

button:focus, .page-tool--button:focus, .page-tool-menu-button:focus, .page-tool-cross:focus, .tools-home-button:focus {
  outline: none; }

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */ }

.inline {
  display: inline-block; }

.closable {
  position: relative; }

.closable-content {
  position: absolute;
  max-height: 95%;
  width: 100%;
  top: 5%; }

.closable-close-button {
  position: absolute;
  height: 4%;
  width: 4%;
  top: 1%;
  left: 95%; }

.page-tool--button {
  flex: 0 0 40%; }

.page-tool-menu-button {
  flex: 0 0 10%; }

.page-tool-cross {
  flex: 0 0 10%; }

.page {
  width: 100vw;
  height: 100vh;
  padding: 6vh 1vw 1vh 1vw;
  background-color: #FFFDFB;
  overflow-y: auto;
  overflow-x: hidden; }

.page-content {
  width: 96vw;
  margin: 1vh 1vw; }

.close-button {
  position: absolute;
  left: 97vw;
  top: 1vh;
  transform: translate(-100%, 50%); }

.page-tool {
  padding: 0 0.2vw 0.2vh 0.2vw;
  background-color: #FFFDFB;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: fixed;
  width: 100vw;
  top: 0;
  height: 5vh; }

@media (min-width: 1023px) {
  .page {
    width: 100vw;
    height: 100vh;
    padding: 4vh 25vw 1vh 25vw;
    background-color: #FFFDFB;
    overflow-y: auto;
    overflow-x: hidden; }
  .close-button {
    position: absolute;
    left: 75vw;
    top: 4vh;
    transform: translate(-100%, 50%); }
  .page-tool {
    padding: 0 0.2vw;
    background-color: #FFFDFB;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 50vw;
    margin: 0 1vw;
    height: 4vh;
    top: 0;
    position: fixed; }
  .page-content {
    width: 50vw;
    margin: 1vh 0; }
  .page-tool-extension {
    position: fixed;
    top: 0;
    left: 0;
    width: 24vw;
    margin: 0;
    height: 100vh; } }

.n, .n-disabled {
  display: inline;
  padding: 0 0.1em !important;
  color: #0E5A8A;
  font-weight: bold; }

.n-disabled {
  display: inline;
  padding: 0 0.1em !important;
  color: #202B33;
  font-weight: bold; }

.n:hover {
  cursor: pointer;
  background-color: #0E5A8A;
  color: #FFFDFB; }

.n-tag {
  margin: 0 0.1em;
  font-weight: bold; }

.rule {
  color: #0E5A8A;
  font-weight: bold;
  background-color: #f7faff;
  padding: 0.5em 1em 0.5em 1em; }

.rule-body {
  color: #0E5A8A;
  font-weight: 400;
  background-color: #f7faff;
  padding: 0 1em 0.5em 1em; }

.active-rule:hover, .accent {
  cursor: pointer;
  background-color: #0E5A8A;
  color: #FFFDFB; }

.error-message {
  cursor: pointer;
  background-color: #A82A2A;
  color: #FFFDFB; }

.poem {
  padding: 0.5em 1em 0.5em 1em;
  white-space: pre; }

@media (min-width: 1023px) {
  .poem {
    padding: 0.5em 7em 0.5em 7em;
    white-space: pre; } }

.process-container--holder {
  width: 98vw; }

.process-container {
  position: fixed;
  top: 10vh;
  left: 0;
  margin: 1vh 1vw;
  width: 98vw; }

@media (min-width: 1023px) {
  .process-container--holder {
    width: 30vw; }
  .process-container {
    position: fixed;
    top: 25vh;
    left: 0;
    margin: 1vh 35vw;
    width: 30vw; } }

.extended-tools-container {
  height: 100%; }

.rules-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
  background-color: #f7faff; }

.tools-home-button {
  width: 100%;
  text-align: center; }

.message-button--login-screen {
  position: fixed;
  top: 0;
  margin: 1vh 1vw;
  width: 98vw; }

.message-window--colors {
  background-color: #106ba3;
  color: white; }

.message-window--input-field {
  margin-top: 0.5vh; }

.message-window--text-area {
  width: 100%;
  display: block; }

@media (min-width: 1023px) {
  .message-button--login-screen {
    position: fixed;
    top: 0;
    left: 0;
    margin: 1vh 35vw;
    width: 30vw; } }

.quotation-container--holder {
  width: 98vw; }

.quotation-container {
  position: fixed;
  top: 99vh;
  left: 0;
  margin: 1vh 1vw;
  width: 98vw;
  max-height: 40vh;
  transform: translate(0, -100%); }

.quotation-content {
  width: 98vw;
  margin: 1vh 0; }

.quotation-button {
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(-100%, 50%); }

.quotation-button--expand {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(10%, 50%); }

.quotation-button-fullscreen {
  position: absolute;
  left: 100%;
  transform: translate(-110%, -50%); }

.quotation--fullscreen {
  margin: 0 auto;
  width: 50vw; }

@media (min-width: 1023px) {
  .quotation-container--holder {
    width: 30vw; }
  .quotation-container {
    position: fixed;
    top: 95vh;
    left: 0;
    margin: 1vh 35vw;
    width: 30vw;
    transform: translate(0, -100%);
    max-height: 30vh; }
  .quotation-content {
    position: relative;
    width: 30vw;
    margin: 1vh 0; }
  .quotation-button {
    position: absolute;
    left: 100%;
    top: 0;
    transform: translate(-110%, 10%); }
  .quotation-button--expand {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(10%, 10%); } }

.login-username {
  flex: 1 1 60%; }

.login-button {
  flex: 1 0; }

.login-icon {
  flex: 0 1; }

.login-username-text--container {
  display: table;
  height: 100%;
  padding-right: 1vw; }

.login-username-text {
  display: table-cell;
  vertical-align: middle; }

.login-email--icon {
  margin: auto;
  display: block; }

.bottom-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
  flex-direction: row; }
